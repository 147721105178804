
  import { reactive, ref, onMounted } from 'vue'
  import { useStore } from "vuex";
  import { Actions } from "@/store/enums/SupplierEnums.ts";
  import { useRouter,useRoute } from "vue-router";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { Actions as StoreActions } from "@/store/enums/StoreEnums.ts";
  export default {
    name : 'SupplierEdit',
    setup(){

      const store = useStore();
      const router = useRouter();
      const route = useRoute();

      const success = ref(false);

      store.dispatch(StoreActions.ADD_BODY_CLASSNAME,'page-loading')

      onMounted(() => {
        setCurrentPageBreadcrumbs("Suppliers",[
          {title : "Suppliers",to : "/suppliers/list"},
          {title : "Edit Supplier",to : "/suppliers/edit/"+route.params.id}
        ]);

        store.dispatch(Actions.GET_SUPPLIER,{id : route.params.id}).then(() => {

          store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME,'page-loading')

        })

      });

      const submitButton = ref<HTMLElement | null>(null);

      const errors = reactive({
        value : {}
      });


      const submitForm = () => {

        if(submitButton.value){
          submitButton.value.setAttribute("data-kt-indicator", "on");
        }

        store.dispatch(Actions.UPDATE_SUPPLIER,store.getters.currentSupplier).then((r) => {
          if(r.success){
            errors.value = [];
            success.value = true;
            window.scrollTo(0,0);
            submitButton.value?.removeAttribute("data-kt-indicator");
            setTimeout(() => {
              router.push('/suppliers/list')
            },1000)
          }

        }).catch((response) => {
          setTimeout(function(){
            const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
            input.focus();
          },100)

          errors.value = response.data.errors;

          submitButton.value?.removeAttribute("data-kt-indicator");

        })
      }

      return {
        submitButton,
        submitForm,
        errors,
        store,
        success
      }
    }
  }
