<template>
  <div>
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Edit Supplier</h3>
        </div>
        <!--end::Card title-->
      </div>
      <!--begin::Card header-->

      <!--begin::Card body-->
      <div class="card-body p-9">
        <div class="row mb-3" v-show="success">
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              Successfully changed supplier!
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="col-form-label required fw-bold fs-6">Name</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.name}" v-model="store.getters.currentSupplier.name" placeholder="Enter name please">
            <span class="text-danger" v-if="errors.value && errors.value.name">{{errors.value && errors.value.name[0]}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <label class="col-form-label required fw-bold fs-6">Address Line 1</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.address1}" v-model="store.getters.currentSupplier.address1" placeholder="Enter address please">
            <span class="text-danger" v-if="errors.value && errors.value.address1">{{errors.value && errors.value.address1[0]}}</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="col-form-label fw-bold fs-6">Address Line 2</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.address2}" v-model="store.getters.currentSupplier.address2" placeholder="Enter address please">
            <span class="text-danger" v-if="errors.value && errors.value.address2">{{errors.value && errors.value.address2[0]}}</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Town / City</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.city}" v-model="store.getters.currentSupplier.city" placeholder="Enter town / city please">
            <span class="text-danger" v-if="errors.value && errors.value.city">{{errors.value && errors.value.city[0]}}</span>
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">County</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.county}" v-model="store.getters.currentSupplier.county" placeholder="Enter county please">
            <span class="text-danger" v-if="errors.value && errors.value.county">{{errors.value && errors.value.county[0]}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Country</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.country}" v-model="store.getters.currentSupplier.country" placeholder="Enter country please">
            <span class="text-danger" v-if="errors.value && errors.value.country">{{errors.value && errors.value.country[0]}}</span>
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Postcode</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.postcode}" v-model="store.getters.currentSupplier.postcode" placeholder="Enter postode please">
            <span class="text-danger" v-if="errors.value && errors.value.postcode">{{errors.value && errors.value.postcode[0]}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <label class="col-form-label required fw-bold fs-6">Email</label>
            <input type="email" class="form-control" :class="{'is-invalid' : errors.value && errors.value.email}" v-model="store.getters.currentSupplier.email" placeholder="Enter email please" :readonly="true">
            <span class="text-danger" v-if="errors.value && errors.value.email">{{errors.value && errors.value.email[0]}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label fw-bold fs-6">Mobile</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.mobile}" v-model="store.getters.currentSupplier.mobile" placeholder="Enter mobile please">
            <span class="text-danger" v-if="errors.value && errors.value.mobile">{{errors.value && errors.value.mobile[0]}}</span>
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label fw-bold fs-6">Tel</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.telephone}" v-model="store.getters.currentSupplier.telephone" placeholder="Enter telephone please">
            <span class="text-danger" v-if="errors.value && errors.value.telephone">{{errors.value && errors.value.telephone[0]}}</span>
          </div>
        </div>

        <div class="row">

          <div class="col-12">
            <label class="col-form-label fw-bold fs-6">Supplier is customer also</label>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="store.getters.currentSupplier.isCustomer">
              <label class="form-check-label" for="flexSwitchCheckChecked"></label>
            </div>
          </div>

        </div>
      </div>
      <!--end::Card body-->
      <div class="card-footer cursor-pointer">
        <!--begin::Card title-->
        <div class="text-end m-0">
          <button
            type="submit"
            ref="submitButton"
            class="btn btn-lg btn-primary fw-bolder me-3 my-2"
            @click="submitForm"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
        <!--end::Card title-->
      </div>
    </div>

  </div>
</template>
<script lang="ts">
  import { reactive, ref, onMounted } from 'vue'
  import { useStore } from "vuex";
  import { Actions } from "@/store/enums/SupplierEnums.ts";
  import { useRouter,useRoute } from "vue-router";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { Actions as StoreActions } from "@/store/enums/StoreEnums.ts";
  export default {
    name : 'SupplierEdit',
    setup(){

      const store = useStore();
      const router = useRouter();
      const route = useRoute();

      const success = ref(false);

      store.dispatch(StoreActions.ADD_BODY_CLASSNAME,'page-loading')

      onMounted(() => {
        setCurrentPageBreadcrumbs("Suppliers",[
          {title : "Suppliers",to : "/suppliers/list"},
          {title : "Edit Supplier",to : "/suppliers/edit/"+route.params.id}
        ]);

        store.dispatch(Actions.GET_SUPPLIER,{id : route.params.id}).then(() => {

          store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME,'page-loading')

        })

      });

      const submitButton = ref<HTMLElement | null>(null);

      const errors = reactive({
        value : {}
      });


      const submitForm = () => {

        if(submitButton.value){
          submitButton.value.setAttribute("data-kt-indicator", "on");
        }

        store.dispatch(Actions.UPDATE_SUPPLIER,store.getters.currentSupplier).then((r) => {
          if(r.success){
            errors.value = [];
            success.value = true;
            window.scrollTo(0,0);
            submitButton.value?.removeAttribute("data-kt-indicator");
            setTimeout(() => {
              router.push('/suppliers/list')
            },1000)
          }

        }).catch((response) => {
          setTimeout(function(){
            const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
            input.focus();
          },100)

          errors.value = response.data.errors;

          submitButton.value?.removeAttribute("data-kt-indicator");

        })
      }

      return {
        submitButton,
        submitForm,
        errors,
        store,
        success
      }
    }
  }
</script>
